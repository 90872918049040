import service from "@/utils/request";

//获取供应商、物料组
export function getListArray() {
  return service({
    method: "get",
    url: "/order/orderList.htm",
  });
}
//获取活动列表
export function getActivityList(params) {
  return service({
    method: "post",
    url: "/marketActivity/queryList.nd",
    params
  }); 
}
// 抢购下单
export function panicBuy(params) {
  return service({
    method: "post",
    url: "/marketActivity/settlement.nd",
    params
  });
}
// 认购下单
export function offerBuy(params) {
  return service({
    method: "post",
    url: "/marketActivity/save.nd",
    params
  });
}

export function unionSave(data) {
  return service({
    method: "post",
    url: "/marketActivity/unionSave.nd",
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  });
}
