<template>
  <div class="countdown-box">
        <p>
          <span class="title">{{preText}}</span>
            <span class="day">{{day}}天 </span>
            <span class="hour">{{hour}}</span>
            <span style="padding-left:5px;padding-right:5px">
              :
            </span>
            <span class="mintes">{{min}}</span>
            <span style="padding-left:5px;padding-right:5px">
              :
            </span>
            <span class="miao"> {{second}} </span>
        </p>
  </div>
</template>
<script>
export default {
  replace: true,
  data() {
    return {
        tipShow: true,
        //倒计时数值
        show: true, //倒计时状态
        day: "", //天
        hour: "", //小时
        min: "", //分钟
        second: "", //秒 
        star: "", //活动开始时间
        end: "", //活动结束时间
        current: "" ,//当前时间
        preText:''
       
    };
  },
  watch: {
  },
  props: {
  
    StartText: {
      type: String,
      default: ""
    },
    endtText: {
      type: String,
      default: ""
    },
    time:{
      type: String,
      default: ""
    },
    endDate:{
      type: String,
      default: ""
    },
    startDate: {
      type: String,
      default: ""
    },
    status:{
       type: String||Number,
      default: ""
    }

  },
  created() {
  },
  mounted() {
   if(this.status == 2) {
      this.curStartTime = this.endDate
      this.preText = '距离结束仅剩'
   }
   if(this.status == 1) {
      this.curStartTime = this.startDate
      this.preText = '距离开始仅剩'
   }
    if(this.status ||  this.curStartTime) {
      this.countTime()
    }
    
  },
  methods: {
   // 倒计时
    countTime () {
      // 获取当前时间
      let date = new Date()
      let now = date.getTime()
      // console.log('data',now);
      // 设置截止时间
      let endDate = new Date(this.curStartTime) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // console.log('endDate',this.curStartTime);
      // console.log('end',end);
      // 时间差
      let leftTime = end - now
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        this.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        this.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        this.second = s < 10 ? '0' + s : s
      } else {
        this.day = 0
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
        return
      } else {
      // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
  }
};
</script>
<style lang="less" scoped>
  .countdown-box {
    margin-top: 20px!important;
    p {
      .title {
        font-size: 12px;
        font-weight: 400;
        color: #262626;
      }
      .day {
        font-size: 12px;
        font-weight: 400;
        color: #D9161C;
      }
      .hour {
        margin-left: 12px;
      }
      .hour, .mintes, .miao {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #262626;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
</style>
