import { mapState } from "vuex";
// 导入api接口
import { getActivityList, offerBuy,unionSave } from "./api.js";
// import { queryOrgAndMatkl } from "@/utils/api";
import countdown from "./components/CountDown";
import countdownTwo from "./components/countdownTwo";
import mySortBar from "./components/SortBar";
import warningImg from "@/assets/icon/warning.png";
import { Number } from "core-js";

export default {
  data() {
    return {
      warningImg: warningImg,
      visible: false,
      renBuyParams: [],
      isSelectAll: false,
      coverList: [],
      // windowList: [],
      totalPages: 0,
      totalRows: 0,
      currentPage: 0,
      sortList: [
        //0默认 1降 2升序
        { name: "开始时间", sortStatus: 1, sortName: "startDate" },
        { name: "结束时间", sortStatus: 0, sortName: "endDate" }
      ],
      pageLoadFlag: false,
      pageSize: 1,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/package",
          name: "package",
          title: "活动专区"
        }
      ],
      visibleTwo: false,
      postListParams: {
        _loading: true,
        pageNo: 1,
        status: "2",
        startDate: "",
        endDate: "",
        activityName: "",
        matkl: "",
        orgId: "",
        loginType: "",
        publishDate: "",
        remainDate: "",
        sortName: "startDate",
        sortOrder: "desc"
      },
      fwOrgName: "",
      showList: false,
      isShowResult: true,
      componSearchVal: "",
      nolistImg: require("@/assets/order/noList.png"),
      isFixed: false,
      checked:false,
      checkActivityArr: [],
      total:0,
      totalVolum:0,
      totalMoney:0,
      // isStock:true,
    };
  },
  components: {
    countdown,
    countdownTwo,
    mySortBar
  },
  watch: {
    $route: function() {
      this.postListParams.condition = this.$route.query.condition
        ? this.$route.query.condition
        : "";
    },
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: state => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: state => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: state => state.filtrate.filterConfigStr
    })
  },
  mounted() {
    window.addEventListener("scroll", this.throttle(this.handleScroll, 100), true);
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.postListParams = {
      ...this.postListParams,
      formCode: this.filterConfigStr ? this.filterConfigStr : ""
    };

    //默认查询入参
    if (this.$route.query.condition) {
      this.postListParams.condition = this.$route.query.condition;
    }
    //最后一项面包屑显示
    let breadcrumbLast = {
      title: this.$route.query.customName ? this.$route.query.customName : ""
    };
    //最后一项面包屑
    if (breadcrumbLast.title) {
      this.$set(
        this.breadcrumbData,
        this.breadcrumbData.length,
        breadcrumbLast
      );
    }

    this.componSearchVal = this.$route.query.activityCode
      ? this.$route.query.activityCode
      : "";
  },
  methods: {
    // isStockChange(e){
    //   this.isStock = e.target.checked
    //   this.$forceUpdate()
    //   this.getActivityList()
    // },
    async allOrder() {
      let listALL = []
      let listOder = []
      this.checkActivityArr = []
      let activityNum = 0
      this.coverList.forEach((item)=>{
        if(item.activityType == '抢购') {
          if(item.discountTypeName == '组合购') {
            listALL = []
            listOder = []
            for(let k in item.arrList) {
              if( item.arrList[k].checkAll) {
                if(item.arrList[k].length > 0) {
                  item.arrList[k].forEach(goodItem=>{
                    if(goodItem.isCheck) {
                      activityNum+=1
                      this.checkActivityArr.push(goodItem)
                    }
                  })
                }
              }
            }
          }
          if(item.discountTypeName == '直降'|| item.discountTypeName == '后返') {
            if(item.productDtoList.length > 0){
              item.productDtoList.forEach(goodItem=>{
                if(goodItem.isCheck){
                  activityNum+=1
                  this.checkActivityArr.push(goodItem)
                }
              })
            }
          }
          if(item.discountTypeName == '套购'){
            if(item.arrList.length>0) {
              item.arrList.forEach(twoItem=>{
                if(twoItem.data.length >0) {
                  if(twoItem.checkAll){
                    twoItem.data.forEach(goodItem=>{
                      if(goodItem.isCheck) {
                        activityNum+=1
                        this.checkActivityArr.push(goodItem)
                      }
                    })
                  }
                }
              })
            }
          }
        }
        if(item.activityType == '认购') {
          if(item.discountTypeName == '套购'){
            let Cherknum = 0
            let isChooseNum = 0
            if(item.arrList.length>0) {
              item.arrList.forEach(twoItem=>{
                if(twoItem.data.length >0) {
                  if(twoItem.checkAll){
                    listALL.push(item)
                    twoItem.data.forEach(goodItem=>{
                      if(!goodItem.isCheck) {
                        // this.$message.warning("套购需要选择该组合全部商品才可进行认购，请重新选择");
                        Cherknum += 1
                      }
                      if (goodItem.isChooseNum <= 0) {
                        // this.$message.warning("请选择数量,认购数量不能为0");
                        isChooseNum +=1
                      }
                    })
                    if(Cherknum>0){
                      this.$message.warning("套购需要选择该组合全部商品才可进行认购，请重新选择");
                      return
                    }

                  }
                  if(twoItem.checkAll){
                    twoItem.data.forEach(goodItem=>{
                      if(!goodItem.isCheck) {
                        // this.$message.warning("套购需要选择该组合全部商品才可进行认购，请重新选择");
                        Cherknum += 1
                      }
                      if (goodItem.isChooseNum <= 0) {
                        // this.$message.warning("请选择数量,认购数量不能为0");
                        isChooseNum +=1
                      }
                    })
                    if(Cherknum>0){
                      // 产品数量选择未满足比例，请重新选择
                      this.$message.warning("套购需要选择该组合全部商品才可进行认购，请重新选择");
                      return
                    }
                  }
                }
              })
            }
          }
        }
      })
      // 直降后返数据 组合跳转
      let zjList = this.coverList.filter(it =>(it.discountTypeName == '直降' ||   it.discountTypeName == '后返') && it.activityType == '认购')
      if (zjList.length >0 && (zjList[0].discountTypeName == '直降'||  zjList[0].discountTypeName == '后返')) {
        zjList.forEach(item=>{
          if(item.productDtoList.length>0){
            for(let i=0;i<item.productDtoList.length;i++) {
              let list = []
              if( item.productDtoList[i].isCheck) {
                listALL.push(item)
                let aaa = [
                  {
                    id:  item.productDtoList[i].id,
                    buyNum:  item.productDtoList[i].isChooseNum
                  }
                ]

                list.push(JSON.parse(JSON.stringify(aaa)))
                list.forEach(listItem=>{
                  listOder.push({
                    "id":item.id,//活动id
                    "orgId":item.fwOrgId,//分公司id
                    list:listItem,
                    name:item.activityName,
                    discountTypeName:item.discountTypeName,
                    orgGroup:item.orgDict
                  })
                })
              }
            }
          }
        })
      }
      // 套购数据
      let tgList = this.coverList.filter(it =>it.discountTypeName == '套购'&& it.activityType == '认购')
      let checkTgList = tgList.filter(it => {
        for(let k in it.arrList)
          if(it.arrList[k].checkAll){
            return it.arrList
          }
      })
      if (checkTgList.length>0 && checkTgList[0].discountTypeName == '套购' ) {
        checkTgList.forEach(item=>{
          for(let k in item.arrList) {
            let list = []
            if( item.arrList[k].checkAll) {
              listALL.push(item)
              let aaa = []
              if(item.arrList[k].data.length>0) {
                item.arrList[k].data.forEach(it=>{
                  if( it.isCheck) {
                    aaa.push({
                      id: it.id,
                      buyNum:(it.packageNum * item.arrList[k].isChooseNum),
                    })
                  }
                })
              }
              list.push(JSON.parse(JSON.stringify(aaa)))
              list.forEach(listItem=>{
                listOder.push({
                  "id":item.id,//活动id
                  "orgId":item.fwOrgId,//分公司id
                  list:listItem,
                  name:item.activityName,
                  discountTypeName:item.discountTypeName,
                  buyOrNot:item.arrList[k].buyOrNot,
                  orgGroup:item.orgDict
                })
              })
            }
          }
        })
      }

      // 组合购数据
      let zuheList = this.coverList.filter(it =>it.discountTypeName == '组合购'&&it.activityType == '认购')
      let checkList = zuheList.filter(it => {
        for(let k in it.arrList)
          if(it.arrList[k].checkAll){
            return it.arrList
          }
      })

      if (checkList.length>0 && checkList[0].discountTypeId == 90605 ) {
        checkList.forEach(item=>{
          for(let k in item.arrList) {
            item.arrList[k].buyOrNot =  this.checkCombinationPurchase(item.arrList[k]);
            let list = []
            if( item.arrList[k].checkAll) {
              listALL.push(item)
              if(item.arrList[k].length > 0) {
                item.arrList[k].forEach(goodItem=>{
                  if(!goodItem.isCheck) {
                    this.$message.warning(`${item.activityName}活动请选中组合购产品，请重新选择`);
                  }
                })
                let aaa = item.arrList[k].reduce((account, el) => {
                  let arr = el.child.reduce((childAcc, childCur) => {
                    if( childCur.isChooseNum > 0) {
                      childAcc.push({
                        id: childCur.id,
                        buyNum: childCur.isChooseNum
                      })
                    }
                    return childAcc
                  }, [])
                  account = [...account, ...arr]
                  return account
                }, [])

                let productGroupRemark = [], packageNumArr = [];
                item.arrList[k].forEach(el => {
                  productGroupRemark.push(el.productGroupRemark)
                  packageNumArr.push(el.packageNum)
                })
                let isMeetProportion = false

                // 给每一项赋值数量 为了获取数量进行操作
                item.arrList[k].forEach(arrItem=>{
                  arrItem.totalNum= 0
                  arrItem.child.forEach(childItem=>{
                    arrItem.totalNum += Number(childItem.isChooseNum?childItem.isChooseNum:0);
                  })
                })
                isMeetProportion = item.arrList[k].every(el => el.totalNum >= el.packageNum)
                this.$forceUpdate()
                list.push(JSON.parse(JSON.stringify(aaa)))
                list.forEach(listItem=>{
                  listOder.push({
                    "id":item.id,//活动id
                    "orgId":item.fwOrgId,//分公司id
                    list:listItem,
                    name:item.activityName,
                    discountTypeName:item.discountTypeName,
                    buyOrNot:item.arrList[k].buyOrNot,
                    orgGroup:item.orgDict,
                    quantityControl:item.arrList[k][0].quantityControl,
                    productGroupRemark:productGroupRemark,
                    packageNumArr:packageNumArr,
                    isMeetProportion:isMeetProportion

                  })
                })
              }
            }
          }
        })
      }
      // 如果选择了抢购  提示信息
      if(activityNum > 0 ) {
        this.$message.warning('抢购活动不能参与多活动同时下单!')
        return
      }
      // 数组去重 组合购 套购不能去重 因为id 是一样的  只是拆开传给后台
      // this.unique(listOder.filter(it=> {return it.discountTypeName !== '组合购'}))
      this.unique(listALL)

      if(listOder .length <= 0){
        this.$message.warning('请先选择商品！')
        return
      } else {
        for(let i = 0;i< listOder.length; i++) {

          if(listOder[i].discountTypeName == '组合购') {
            if(listOder[i].quantityControl == 1){
              if(!listOder[i].isMeetProportion ){
                this.$message.warning(`该组合活动的 ${listOder[i].productGroupRemark.join('和')}的数量的起订量分别为${listOder[i].packageNumArr.join('和')}` )
                return
              }
            }else{
              if(!listOder[i].buyOrNot) {
                // 产品数量选择未满足比例，请重新选择
                // this.$message.warning(`${listOder[i].name}活动下单数量不符合组合购比例，请重新选择`);
                this.$message.warning(`该组合活动的${listOder[i].productGroupRemark.join('和')}的数量需要满足${listOder[i].packageNumArr.join(':')}的比例`)
                return
              }
            }

          }
          if(listOder[i].discountTypeName !== '组合购') {
            if(listOder[i].list.length > 0) {
              for(let j = 0;j < listOder[i].list.length; j++){
                if((Number(listOder[i].list[j].packageNum) * Number(listOder[i].list[j].isChooseNum)) <= 0) {
                  this.$message.warning(`${listOder[i].name}活动下单数量不能为0，请重新选择`);
                  return
                }
              }
            }
          }
        }
        this.$router.push({
          path:'/combinedOrderSettlement',
          query:{
            listOder:JSON.stringify(listOder),
            listALL:JSON.stringify(listALL),
            page:'acivitylist'
          }
        })
      }
    },
    // 数组去重
    unique(arr){
      for(var i=0; i<arr.length; i++){
        for(var j=i+1; j<arr.length; j++){
          if(arr[i].id==arr[j].id){         //第一个等同于第二个，splice方法删除第二个
            arr.splice(j,1);
            j--;
          }
        }
      }
      return arr;
    },
    // 全选按钮
    allCherk(e){
      this.checked = e.target.checked
      this.$forceUpdate()
      if(this.checked) {
        this.total = 0
        this.totalVolum = 0
        this.totalMoney = 0
        this.coverList.forEach(item=>{
          if(item.activityType == '认购') {
            if( item.discountTypeName == '组合购' ) {
              for(let k in item.arrList){
                item.arrList[k].checkAll = true
                this.total += Number(item.arrList[k].totalProNum || 0)
                this.totalVolum += Number(item.arrList[k].totalVolume  || 0)
                this.totalMoney += Number(item.arrList[k].totalPrice  || 0)
                item.arrList[k].forEach(goodItem=>{
                  goodItem.isCheck = true
                  this.$forceUpdate()
                })
              }
            }
            if( item.discountTypeName == '后返' ||item.discountTypeName == '直降' ) {
              // item.totalProNum = item
              this.total += Number(item.totalProNum)
              this.totalVolum +=  Number(item.totalVolume)
              this.totalMoney +=  Number(item.totalPrice)
              item.productDtoList.forEach(goodItem=>{
                goodItem.isCheck = true
                this.$forceUpdate()
              })
            }
            if( item.discountTypeName == '套购' ) {
              item.arrList.forEach(twoItem=>{
                twoItem.checkAll = true
                twoItem.totalProNum = 0
                twoItem.totalVolume = 0
                twoItem.totalPrice = 0
                this.$forceUpdate()
                twoItem.data.forEach(goodItem=>{
                  goodItem.isCheck = true
                  twoItem.totalProNum += twoItem.isChooseNum * Number(goodItem.packageNum)
                  twoItem.totalVolume += twoItem.isChooseNum * Number(goodItem.volume)
                  twoItem.totalPrice += twoItem.isChooseNum * Number(goodItem.billPrice)
                  this.$forceUpdate()
                })
                this.total += Number(twoItem.totalProNum)
                this.totalVolum +=  Number(twoItem.totalVolume)
                this.totalMoney +=  Number(twoItem.totalPrice)
              })
            }
          }

        })
      } else {
        this.total = 0
        this.totalVolum = 0
        this.totalMoney = 0
        this.coverList.forEach(item=>{
         if(item.activityType == '认购') {
           if( item.discountTypeName == '组合购' ) {
             for(let k in item.arrList){
               item.arrList[k].checkAll = false
               item.arrList[k].forEach(goodItem=>{
                 goodItem.isCheck = false
                 this.$forceUpdate()
               })
             }
           }
           if( item.discountTypeName == '后返' ||item.discountTypeName == '直降' ) {
             item.productDtoList.forEach(goodItem=>{
               goodItem.isCheck = false
               this.$forceUpdate()
             })
           }
           if( item.discountTypeName == '套购' ) {
             item.arrList.forEach(twoItem=>{
               twoItem.checkAll = false
               this.$forceUpdate()
               twoItem.data.forEach(goodItem=>{
                 goodItem.isCheck = false
                 this.$forceUpdate()
               })
             })
           }
         }
        })
      }

    },
    // 选中当前活动
    SelectCurrent(e,item,item1) {
      item1.checkAll = e.target.checked
      this.$forceUpdate()
      if(e.target.checked) {
        if( item.discountTypeName == '组合购' ) {
          item1.forEach(goodItem=>{
            goodItem.isCheck = true
            this.$forceUpdate()
          })
          return
        }
        if( item.discountTypeName == '套购' ) {
          item1.totalProNum = 0;
          item1.totalVolume = 0;
          item1.totalPrice = 0;
          if(item1.checkAll){
            item1.data.forEach(goodItem=>{
              goodItem.isCheck = true
              let choseNum = item1.isChooseNum;
              if (goodItem.isCheck && goodItem.isCheck == true) {
                item1.totalProNum += (Number(choseNum) * Number(goodItem.packageNum));
                item1.totalVolume += Number(goodItem.volume) * (Number(choseNum) * Number(goodItem.packageNum)).toFixed(3);
                item1.totalPrice += Number(goodItem.billPrice) * (Number(choseNum) * Number(goodItem.packageNum)).toFixed(2);
              }
              this.$forceUpdate()
            })
          } else {
            item1.totalProNum = 0;
            item1.totalVolume = 0;
            item1.totalPrice = 0;
          }
          return
        }
        item.productDtoList.forEach(goodItem=>{
          goodItem.isCheck = true
          this.$forceUpdate()
        })
      } else {
        if( item.discountTypeName == '组合购') {
          item1.forEach(goodItem=>{
            goodItem.isCheck = false
            this.$forceUpdate()
          })
          return
        }
        if( item.discountTypeName == '套购' ) {
          item1.data.forEach(goodItem=>{
            goodItem.isCheck = false
            this.$forceUpdate()
          })
          return
        }
        item.productDtoList.forEach(goodItem=>{
          goodItem.isCheck = false
          this.$forceUpdate()
        })
      }
    },
    // 滚动
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
    // 滚动页面
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    // 展开或者收起
    showOrClose(data, index) {
      data[index].isMore = !data[index].isMore;
      this.$forceUpdate();
    },
    // 点击型号切换展示型号
    changeClass(data, index, child, itemIndex) {
      // 点击型号 切换对应的型号展示
      child[itemIndex].child.map((item) => {
        item.isActive = false;
        return item;
      });
      child[itemIndex] = {

        ...child[itemIndex],
        ...child[itemIndex].child[index]
      };
      child[itemIndex].cherkCode = data.productCode;
      this.$forceUpdate();
    },
    getMsg(id) {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id
      });
      if (msg && msg.msgDesc) {
        return msg.msgDesc;
      }
    },
    formatPrice(price) {
      // if (Math.floor(price) === price) {
      //   return price
      // } else {
      return (Math.round(new Number(price) * 1000) / 1000).toFixed(2);
      // }
    },
    toDetail(proCode, orgId) {
      this.$router.push({
        path: "/product/detail",
        query: { productCode: proCode, orgId: orgId }
      });
    },
    // 获取活动列表
    getActivityList() {
      this.pageLoadFlag = true;
      this.showList = false;
      this.coverList = [];
      this.postListParams = {
        ...this.postListParams,
        // isStock:this.isStock
      }
      getActivityList(this.postListParams)
        .then(res => {
          this.pageLoadFlag = false;

          if (res.data.list) {
            this.coverList = this.getNewList(res.data.list);
            // 初始化页面展示页面数据 数量 体积 金额合计
            if(this.coverList&& this.coverList.length > 0) {
              this.coverList.forEach((item, index) => {
                if (item.discountTypeName === "直降" || item.discountTypeName === "后返") {
                  item.totalProNum = 0;
                  item.totalVolume = 0;
                  item.totalPrice = 0;
                  item.productDtoList.forEach(goodsItem => {
                    if(goodsItem.activityType == '认购' && goodsItem.purchaseMinLimitQty){
                      item.totalProNum =
                        Number(item.totalProNum) + Number(goodsItem.purchaseMinLimitQty);
                      item.totalVolume = (
                        Number(item.totalVolume) +
                        Number(goodsItem.volume) * Number(goodsItem.purchaseMinLimitQty)
                      ).toFixed(3);
                      item.totalPrice = (
                        Number(item.totalPrice) +
                        Number(goodsItem.billPrice) * Number(goodsItem.purchaseMinLimitQty)
                      ).toFixed(2);
                    }
                  });
                }
              });

            }

          }
          this.showList = true;
          this.totalPages = res.data.totalPages;
          this.totalRows = res.data.totalRows;
          this.currentPage = res.data.currentPage;

          this.coverList.forEach((element) => {
            // 套购、组合购产品组根据'packageCode'字段分F组
            let groups = {};
            if (element.discountTypeName == "套购") {
              element.arrList = this.changeData(element.productDtoList);
              element.arrList.forEach((ppItem, ppIndex) => {
                ppItem.data.forEach((goodsItem, goodeIndex) => {
                      ppItem.totalProNum = 0;
                      ppItem.totalVolume = 0;
                      ppItem.totalPrice = 0;
                      goodsItem.isChooseNum = 1;
                });
              });
              // isChooseNum
            }
            if (element.discountTypeName == "组合购") {
              element.productDtoList.forEach((res) => {
                let value = res["packageCode"];
                groups[value] = groups[value] || [];
                groups[value].push(res);
              });
            }
            element.setPurchaseNumber = Object.keys(groups);
            if (element.discountTypeName == "组合购") {
              element.setPurchaseNumber.forEach((item) => {
                //组合购将数组里'productGroup'属性相同的对象合并成一个数组
                groups[item] = this.changeGroup(groups[item]);
              });
              element.arrList = groups;
            }
          });
        })
        .catch(error => {
          this.pageLoadFlag = false;
          console.log(error);
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    changeGroup(resData) {
      //将数组里某个属性相同的对象合并成一个数组
      let dataInfo = {};
      resData.forEach((item, index) => {
        let { productGroup } = item;
        if (!dataInfo[productGroup]) {
          dataInfo[productGroup] = {
            productGroup,
            child: []
          };
        }
        dataInfo[productGroup].child.push(item);
      });
      let list = Object.values(dataInfo); // list 转换成功的数据

      // 设置默认显示产品型号
      list = list.map((res, index) => {
        res.child = res.child.map((child, idx) => {
          child.isActive = false;
          if (idx == 0) {
            child.isActive = true;
          }
          return child;
        });
        return {
          ...res,
          ...res.child[0]
        };
      });

      list.forEach(listItem => {
        listItem.cherkCode = "";
        listItem.cherkCode = listItem.child[0].productCode;
        listItem.isMore = true;
      });
      this.$forceUpdate();
      return list;

    },
    // 套购处理数据
    changeData(data) {
      var map = {},
        dest = [];
      for (var i = 0; i < data.length; i++) {
        var ai = data[i];
        if (!map[ai.packageCode]) {
          dest.push({
            packageCode: ai.packageCode,
            isChooseNum: 1,
            totalProNum: 0,
            totalVolume: 0,
            totalPrice: 0,
            data: [ai],
            canBuy: ai.canBuy
          });
          map[ai.packageCode] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.packageCode == ai.packageCode) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;

    },
    // 加载更多
    clickMore() {
      this.pageLoadFlag = true;
      this.postListParams.pageNo = this.postListParams.pageNo + 1;
      getActivityList(this.postListParams)
        .then(res => {
          this.pageLoadFlag = false;

          this.totalPages = res.data.totalPages;
          this.totalRows = res.data.totalRows;
          this.currentPage = res.data.currentPage;
          res.data.list.forEach((element) => {
            // 套购、组合购产品组根据'packageCode'字段分F组
            let groups = {};
            if (element.discountTypeName == "套购") {
              element.arrList = this.changeData(element.productDtoList);
            }
            if (element.discountTypeName == "组合购") {
              element.productDtoList.forEach((res) => {
                let value = res["packageCode"];
                groups[value] = groups[value] || [];
                groups[value].push(res);
              });
            }
            element.setPurchaseNumber = Object.keys(groups);
            if (element.discountTypeName == "组合购") {
              element.setPurchaseNumber.forEach((item) => {
                //组合购将数组里'productGroup'属性相同的对象合并成一个数组
                groups[item] = this.changeGroup(groups[item]);
              });
              element.arrList = groups;
            }
          });
          this.coverList = this.coverList.concat(
            this.getNewList(res.data.list)
          );
        })
        .catch(error => {
          console.log(error);
        });
    },

    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.postListParams = {
        ...this.postListParams,
        ...checkedObj[0],
        pageNo: 1
      };
      //查询列表
      if (this.componSearchVal) {
        this.onSearch(this.componSearchVal);
      } else {
        this.getActivityList();
      }
    },

    // 列表数据重构
    getNewList(obj) {
      obj.forEach(item => {
        (item.totalVolume = 0),
          (item.totalProNum = 0),
          (item.totalPrice = 0),
          item.productDtoList.forEach(goodsItem => {
            (goodsItem.isChooseNum = 0), (goodsItem.VolumnisCheck = false);
          });
      });
      return obj;
    },
    // 多选框
    checkDataChange(id, index, type, data, proItem, item, arrIndex) {
      if (type !== "套购" && type !== "组合购") {
        this.coverList.forEach(item => {
          if (item.id == id) {
            if (item.productDtoList.isCheck) {
              item.totalProNum = 0
              item.totalVolume = 0
              item.totalPrice = 0
              item.totalProNum =
                Number(item.totalProNum) +
                Number(item.productDtoList[index].isChooseNum);
              item.totalVolume = (
                Number(item.totalVolume) +
                Number(item.productDtoList[index].volume) *
                Number(item.productDtoList[index].isChooseNum)
              ).toFixed(3);
              item.totalPrice = (
                Number(item.totalPrice) +
                Number(item.productDtoList[index].billPrice) *
                Number(item.productDtoList[index].isChooseNum)
              ).toFixed(2);
            } else {
              item.totalProNum = 0;
              item.totalVolume = 0;
              item.totalPrice = 0;
              item.productDtoList.forEach(goodsItem => {
                if (goodsItem.isCheck) {
                  item.totalProNum =
                    Number(item.totalProNum) + Number(goodsItem.isChooseNum);
                  item.totalVolume = (
                    Number(item.totalVolume) +
                    Number(goodsItem.volume) * Number(goodsItem.isChooseNum)
                  ).toFixed(3);
                  item.totalPrice = (
                    Number(item.totalPrice) +
                    Number(goodsItem.billPrice) * Number(goodsItem.isChooseNum)
                  ).toFixed(2);
                }
              });
            }
          }
        });
      }
      // 如果是套购的话,计算体积和数量要根据传过来的数据
      if (type == "套购") {
        let num = 0
        this.coverList.forEach(item => {
          if (item.id === id) {
            item.arrList.forEach(ppItem => {
              ppItem.totalProNum = 0;
              ppItem.totalVolume = 0;
              ppItem.totalPrice = 0;
              ppItem.data.forEach(goodsItem => {
                let choseNum = data.isChooseNum;
                if (goodsItem.isCheck && goodsItem.isCheck == true) {
                  num += 1
                  ppItem.totalProNum =
                    Number(ppItem.totalProNum) +
                    (Number(choseNum) * Number(goodsItem.packageNum));
                  ppItem.totalVolume = (
                    Number(ppItem.totalVolume) +
                    Number(goodsItem.volume) * (Number(choseNum) * Number(goodsItem.packageNum))
                  ).toFixed(3);
                  ppItem.totalPrice = (
                    Number(ppItem.totalPrice) +
                    Number(goodsItem.billPrice) * (Number(choseNum) * Number(goodsItem.packageNum))
                  ).toFixed(2);
                }

              });
            });
          }
        });
        if(num > 0) {
          data.checkAll = true
          this.$forceUpdate()
        } else {
          data.checkAll = false
          this.$forceUpdate()
        }
        this.$forceUpdate();
      }
      if (type == "组合购") {
        let num = 0
        for (let k in item) {
          if (k == arrIndex) {
            item[k].totalProNum = 0;
            item[k].totalVolume = 0;
            item[k].totalVolume = 0;
            item[k].totalPrice = 0;
            item[k].forEach(aaaa => {
              if (aaaa.isCheck) {
                num+= 1
                aaaa.child.forEach(childItem => {
                  item[k].totalProNum += Number(childItem.isChooseNum);
                  item[k].totalVolume += Number(childItem.isChooseNum) * Number(childItem.volume);
                  item[k].totalPrice += Number(childItem.isChooseNum) * Number(childItem.billPrice);
                });
              }
            });
          }
          if(num > 0) {
            proItem.checkAll = true
            this.$forceUpdate()
          } else {
            proItem.checkAll = false
            this.$forceUpdate()
          }
        }
        this.$forceUpdate();
      }

      // 控制全选按钮  如果数字和列表长度相等 全选按钮选中 否则就取消选中
      let cherk = 0
      let listLength = 0
      this.coverList.forEach(item=>{
        if(item.activityType == '认购'){
          listLength += 1
          if( item.discountTypeName == '组合购' ) {
            for(let k in item.arrList){
              if( item.arrList[k].checkAll ) {
                cherk += 1
              }
            }
          }
          if( item.discountTypeName == '后返' ||item.discountTypeName == '直降' ) {
            item.productDtoList.forEach(goodItem=>{
              if(goodItem.isCheck){
                cherk += 1
              }
              this.$forceUpdate()
            })
          }
          if( item.discountTypeName == '套购' ) {
            item.arrList.forEach(twoItem=>{
              if(  twoItem.checkAll){
                cherk += 1
              }
              this.$forceUpdate()
            })
          }
        }

      })
      if(cherk == listLength && cherk!==0){
        this.checked = true
      } else {
        this.checked = false
      }

    },
    // 数量加减
    dataChange(e, id, index, type, data, proItem, item, arrIndex, itemIndex) {
      if (type !== "套购" && type !== "组合购") {
        if (data.isChooseNum !== 0) {
          data.isCheck = true;
        } else {
          data.isCheck = false;
        }
        this.coverList.forEach(item => {
          if (item.id == id) {
            if (item.productDtoList[index].isCheck) {
              item.productDtoList[index].isChooseNum = Number(e);
              item.totalProNum = 0;
              item.totalVolume = 0;
              item.totalPrice = 0;
              item.productDtoList.forEach(goodsItem => {
                if (goodsItem.isCheck) {
                  item.totalProNum =
                    Number(item.totalProNum) + Number(goodsItem.isChooseNum);
                  item.totalVolume = (
                    Number(item.totalVolume) +
                    Number(goodsItem.volume) * Number(goodsItem.isChooseNum)
                  ).toFixed(3);
                  item.totalPrice = (
                    Number(item.totalPrice) +
                    Number(goodsItem.billPrice) * Number(goodsItem.isChooseNum)
                  ).toFixed(2);
                }
              });
            }
          }
        });
      }
      // 如果是套购的话,计算体积和数量要根据传过来的数据
      if (type == "套购") {
        // 如果选择套购数量小于等于0  全选表示不勾选
        if (data.isChooseNum <= 0) {
          index.arrList[proItem].checkAll = false
        }
        // 只要点击数量加减  全选按钮就勾选
        index.arrList[proItem].checkAll = true;
        this.$forceUpdate()
        index.arrList[proItem].totalPrice = 0
        index.arrList[proItem].totalProNum = 0
        index.arrList[proItem].totalVolume = 0
        index.arrList[proItem].data.forEach(pro =>{
          // 选择数量 每行产品都勾选为true
          pro.isCheck = true
          // 计算选择的套购活动的体积 数量 价格
          index.arrList[proItem].totalProNum += (Number(pro.packageNum) * Number(data.isChooseNum))
          index.arrList[proItem].totalVolume += (Number(pro.packageNum) * Number(data.isChooseNum) * Number(pro.volume))
          index.arrList[proItem].totalPrice += (Number(pro.packageNum) * Number(data.isChooseNum) * Number(pro.billPrice) )
          this.$forceUpdate()
        })
        // this.coverList.forEach(item => {
        //   if (item.id === id) {
        //     item.arrList.forEach((ppItem, ppIndex) => {
        //       ppItem.data.forEach((goodsItem, goodeIndex) => {
        //         if (ppIndex == proItem) {
        //           goodsItem.isCheck = true;
        //           // 外层全选按钮
        //           data.checkAll = true
        //         }
        //
        //         let choseNum = data.isChooseNum;
        //         // 如果数量是0 取消选中
        //         if (data.isChooseNum <= 0) {
        //           data.checkAll = false
        //         }
        //
        //         if (goodsItem.isCheck && goodsItem.isCheck == true) {
        //           ppItem.totalProNum = 0;
        //           ppItem.totalVolume = 0;
        //           ppItem.totalPrice = 0;
        //           if(goodsItem.packageCode === data.packageCode){
        //             goodsItem.isChooseNum = e
        //             console.log('数量',goodsItem.isChooseNum);
        //             console.log('数量总和',goodsItem.isChooseNum*goodsItem.packageNum);
        //             ppItem.totalProNum += (goodsItem.isChooseNum*goodsItem.packageNum)
        //
        //             //
        //             // ppItem.totalProNum += (Number(data.isChooseNum) * (Number(goodsItem.packageNum)+Number(goodsItem.packageNum)));
        //             // ppItem.totalVolume = (
        //             //   Number(ppItem.totalVolume) +
        //             //   Number(goodsItem.volume) * (Number(choseNum) * (Number(goodsItem.packageNum)+Number(goodsItem.packageNum)))
        //             // ).toFixed(3);
        //             // ppItem.totalPrice = (
        //             //   Number(ppItem.totalPrice) +
        //             //   Number(goodsItem.billPrice) * (Number(choseNum) * (Number(goodsItem.packageNum)+Number(goodsItem.packageNum)))
        //             // ).toFixed(2);
        //           }
        //
        //         }
        //       });
        //     });
        //   }
        // });
        this.$forceUpdate();
      }
      if (type == "组合购") {
        for (let k in item) {
          if (k == arrIndex) {
            item[k].totalProNum = 0;
            item[k].totalVolume = 0;
            item[k].totalPrice = 0;
            //选中状态num
            let cherkNum = 0
            item[k].forEach((aaaa, aaaIndex) => {
              if (aaaIndex == itemIndex) {
                // 选择数量对应的组的多选框自动勾选
                aaaa.isCheck = true;
                this.$forceUpdate()
              }
              if (aaaa.isCheck) {
                aaaa.isChooseNum = e;
                aaaa.child.forEach(childItem => {
                  item[k].totalProNum += Number(childItem.isChooseNum);
                  item[k].totalVolume += Number(childItem.isChooseNum) * Number(childItem.volume);
                  item[k].totalPrice += Number(childItem.isChooseNum) * Number(childItem.billPrice);
                });
              }
              // 把数据为0 的push到一个数组中 进行判断 如果和原数组数据长度一致 对应的多选框则去除勾选状态
              // 必须是全部都是0才可以去调勾选
              let isChooseArr = [];

              aaaa.child.forEach(childItem => {
                if (childItem.isChooseNum == 0) {
                  isChooseArr.push(childItem);
                }
              });
              if (isChooseArr.length == aaaa.child.length) {
                aaaa.isCheck = false;
              }

              if(aaaa.isCheck) {
                cherkNum+=1
              }

            });
            // 如果勾中数量和数据长度一致 外层的勾选框为选中状态
            if(cherkNum == item[k].length) {
              item[k].checkAll = true
              this.$forceUpdate()
            } else {
              item[k].checkAll = false
              this.$forceUpdate()
            }


          }
        }
        this.$forceUpdate();
      }
    },
    showModal() {
      this.visible = true;
    },
    // handleOk() {
    //   this.visible = false;
    // },
    onSearch(activityName) {
      this.preperList = [];
      this.postListParams = { ...this.postListParams, activityName, page: 1 };
      this.getActivityList();
      this.isShowResult = true;
    },
    //筛选排序
    sortClick(data) {
      const { sortName, sortStatus } = data;
      this.postListParams = {
        ...this.postListParams,
        sortName: sortName,
        sortOrder: sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc",
        page: 1
      };
      this.getActivityList();
    },
    handlePageChange() {
      this.getActivityList();
    },
    // 认购
    showConfirm(id, pause, item, proItem, proIndex, fwOrgId) {
      if (item.discountTypeName == "组合购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        let cherk = false;
        proItem.forEach(item => {
          if (item.isCheck) {
            cherk = true;
          }
        });
        for (let a = 0; a < proItem.length; a++) {
          if (!proItem[a].isCheck) {
            cherk = false;
            this.$message.warning("组合购要选择该组合全部商品才可进行认购，请重新选择");
            return false;
          } else {
            cherk = true;
          }

        }
        if (!cherk) {
          this.$message.warning("请勾选要购买的产品");
          return;
        }
        if (cherk) {
          let buyOrNot = false;
          const quantityControl = proItem[0].quantityControl
          // 给每一项赋值数量 为了获取数量进行操作
          proItem.forEach(el =>{
            el.totalNum= 0
            el.child.forEach(childItem=>{
              el.totalNum += Number(childItem.isChooseNum?childItem.isChooseNum:0);
            })
          })
          let productGroupRemark = [], packageNumArr = [];
          proItem.forEach(el => {
            productGroupRemark.push(el.productGroupRemark)
            packageNumArr.push(el.packageNum)
          })

          // 按数量校验
          if(quantityControl == 1){
            let isMeetProportion = false
            isMeetProportion = proItem.every(el => el.totalNum >= el.packageNum)
            if(!isMeetProportion ){
              this.$message.warning(`该组合活动的 ${productGroupRemark.join('和')}的数量的起订量分别为${packageNumArr.join('和')}` )
            }else{
              let routeUrl = this.$router.resolve({
                path: "/activesubscriptionPage",
                query: {
                  item: JSON.stringify(item),
                  proIndex: proIndex
                }
              });
              window.open(routeUrl.href, "_blank");
              return
            }
          } else{
            buyOrNot = this.checkCombinationPurchase(proItem);

            if (buyOrNot) {
              let routeUrl = this.$router.resolve({
                path: "/activesubscriptionPage",
                query: {
                  item: JSON.stringify(item),
                  proIndex: proIndex
                }
              });
              window.open(routeUrl.href, "_blank");
              return
            } else {
              this.$message.warning(`该组合活动的${productGroupRemark.join('和')}的数量需要满足${packageNumArr.join(':')}的比例`);

            }
          }

        }
      }
      if (item.discountTypeName == "套购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        let tipsNum = 0
        for (let a = 0; a < proItem.data.length; a++) {
          if (!proItem.data[a].isCheck) {
            tipsNum+=1
          } else if (proItem.isChooseNum <= 0) {
            this.$message.warning("请选择数量,认购数量不能为0");
            return;
          }
        }

        if(tipsNum <= 0){
          // this.$router.push({
          //   path: "/activesubscriptionPage",
          //   query: {
          //     item: JSON.stringify(item),
          //     proIndex: proIndex,
          //   }
          // });
          let routeUrl = this.$router.resolve({
            path: "/activesubscriptionPage",
            query: {
              item: JSON.stringify(item),
              proIndex: proIndex,
            }
          });
          window.open(routeUrl.href, "_blank");
          return
        }else{
          this.$message.warning("套购需要选择该组合全部商品才可进行认购，请重新选择");
          return;
        }
      }
      if (item.discountTypeName !== "套购" && item.discountTypeName !== "组合购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        this.renBuyParams = [];
        this.coverList.forEach(item => {
          if (item.id == id) {
            let isTrue = false;
            let xian = false;
            let CherkNum = 0;
            let ischerk = 0;
            item.productDtoList.forEach(goodsItem => {
              if (goodsItem.isCheck) {
                CherkNum += 1;
                isTrue = true;
                ischerk = goodsItem.isChooseNum;
              } else {
                if (goodsItem.purchaseLimitQty == goodsItem.purchaseQty) {
                  this.$message.info("商家抢购数量达到上限");
                  return;
                }
              }

            });
            if (!isTrue) {
              if (!xian) {
                this.$message.info("请选择商品");
              }
            } else if (CherkNum > 1) {
              this.$message.info("请只选择一件商品进行抢购");
              return false;
            } else if (ischerk <= 0) {
              this.$message.info("认购数量不能为0，请重新选择数量");
              return false;

            } else {
              // this.$router.push({
              //   path: "/activesubscriptionPage",
              //   query: {
              //     item: JSON.stringify(item)
              //   }
              // });
              let routeUrl = this.$router.resolve({
                path: "/activesubscriptionPage",
                query: {
                  item: JSON.stringify(item)
                }
              });
              window.open(routeUrl.href, "_blank");
              return
            }
          }
        });
      }
    },
    //认购确认
    handleSubmit() {
      this.visibleTwo = false;
      this.pageLoadFlag = true;

      Promise.all(
        this.renBuyParams.map(it => {
          return offerBuy(it);
        })
      )
        .then(ress => {
          const resus = [];
          for (let i = 0; i < ress.length; i++) {
            resus.push(
              this.renBuyParams[i].fwOrgName + "," + ress[i].data.orderCode
            );
          }
          this.pageLoadFlag = false;
          this.$router.push({
            path: "submitEndActivity",
            query: {
              resus: resus.join("$$")
            }
          });
        })
        .catch(err => {
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: "14180000051"
          });
          this.$message.warning(msg && msg.msgDesc);
        });

    },
    // 判断组合购是否符合 条件
    checkCombinationPurchase(proItem) {
      let standardValArr = []; // 每个组实际要求数量
      let currValArr = []; // 每个组购买总数
      let multipleArr = []; // 每个组购买总数与实际要求数量成比例
      proItem.forEach((val) => {
        val.numOrder = 0;
        val.child.forEach(item => {
          val.numOrder += item.isChooseNum;

        });
        standardValArr.push(val.packageNum);
        currValArr.push(val.numOrder);
        multipleArr.push(val.numOrder % val.packageNum);
      });
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] == 0) {
          return false;
        }
      }
      for (let i = 0; i < multipleArr.length; i++) {
        if (multipleArr[i] != 0) {
          return false;
        }
      }
      let multiple = currValArr[0] / standardValArr[0]; //先生成一个参考比例
      // 判断每组购买总数比例是否与实际要求比例相同
      for (let i = 0; i < currValArr.length; i++) {
        if (currValArr[i] / standardValArr[i] != multiple) {
          return false;
        }
      }
      return true;
    },
    // 去结算
    toConfirm(id, pause, data, proItem, proIndex, fwOrgId, custId) {
      if (data.discountTypeName == "组合购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        let cherk = false;
        proItem.forEach(item => {
          if (item.isCheck) {
            cherk = true;
          }
        });
        for (let a = 0; a < proItem.length; a++) {
          if (!proItem[a].isCheck) {
            cherk = false;
            this.$message.warning("组合购要选择该组合全部商品才可进行抢购，请重新选择");
            return false;
          } else {
            cherk = true;
          }
        }
        if (!cherk) {
          this.$message.warning("请勾选要购买的产品");
          return;
        }
        if (cherk) {
          let buyOrNot = false;
          const quantityControl = proItem[0].quantityControl
          // 给每一项赋值数量 为了获取数量进行操作
          proItem.forEach(items=>{
            items.totalNum= 0
            items.child.forEach(childItem=>{
              items.totalNum += Number(childItem.isChooseNum?childItem.isChooseNum:0);
            })
          })
          let productGroupRemark = [], packageNumArr = [];
          proItem.forEach(el => {
            productGroupRemark.push(el.productGroupRemark)
            packageNumArr.push(el.packageNum)
          })
          // 按数量校验
          if(quantityControl == 1){
            let isMeetProportion = false
            isMeetProportion = proItem.every(el => el.totalNum >= el.packageNum)
            if(!isMeetProportion ){
              this.$message.warning(`该组合活动的 ${productGroupRemark.join('和')}的数量的起订量分别为${packageNumArr.join('和')}` )
            }else{

              // 每个大组进行循环 再循环child数组 获取到对应的型号和数量
              let modelIds = []; // 需要传给后台的型号id
              let modeNum = []; // 需要传给后台的对应的型号的数量
              proItem.forEach(item => {
                // 循环
                item.child.forEach(childItem => {
                  if (childItem.isChooseNum !== 0) {
                    modelIds.push(childItem.id);
                    modeNum.push(childItem.isChooseNum);
                  }
                });
              });

              // 条件符合跳转下单页面
              if (custId && (custId !== null || custId !== "")) {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/channelSubOrder",
                  query: {
                    prdIds: modelIds.join(","),
                    buyNums: modeNum.join(","),
                    orgId: fwOrgId,
                    custId: custId,
                    pageType:'activity'
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              } else {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/activity",
                  query: {
                    prdIds: modelIds.join(","),
                    buyNums: modeNum.join(","),
                    orgId: fwOrgId,
                    item: JSON.stringify(data)
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              }
            }
          } else{
            buyOrNot = this.checkCombinationPurchase(proItem);
            if (!buyOrNot) {
              this.$message.warning(`该组合活动的${productGroupRemark.join('和')}的数量需要满足${packageNumArr.join(':')}的比例`);
            } else {
              // 每个大组进行循环 再循环child数组 获取到对应的型号和数量
              let modelIds = []; // 需要传给后台的型号id
              let modeNum = []; // 需要传给后台的对应的型号的数量
              proItem.forEach(item => {
                // 循环
                item.child.forEach(childItem => {
                  if (childItem.isChooseNum !== 0) {
                    modelIds.push(childItem.id);
                    modeNum.push(childItem.isChooseNum);
                  }
                });
              });

              // 条件符合跳转下单页面
              if (custId && custId !== null) {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/channelSubOrder",
                  query: {
                    prdIds: modelIds.join(","),
                    buyNums: modeNum.join(","),
                    orgId: fwOrgId,
                    custId: custId,
                    pageType:'activity'
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              } else {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/activity",
                  query: {
                    prdIds: modelIds.join(","),
                    buyNums: modeNum.join(","),
                    orgId: fwOrgId,
                    item: JSON.stringify(data)
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              }

            }
          }

        }
      }
      if (data.discountTypeName == "套购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        for (let a = 0; a < proItem.data.length; a++) {
          if (!proItem.data[a].isCheck) {
            this.$message.warning("套购需要选择该组合全部商品才可进行抢购，请重新选择");
            return false;
          } else if (proItem.isChooseNum <= 0) {
            this.$message.warning("请选择数量,认购数量不能为0");
            return false;
          } else {
            let obj =  this.coverList.filter(it=>it.id == id)
            let isTrue = false;
            let prdIds = "";
            let buyNums = [];
            let fwOrgId = "";
            let money = 0;
            obj[0].arrList.forEach(aa => {
              aa.data.forEach(goodsItem => {
                if (goodsItem.isCheck) {
                  isTrue = true;
                  prdIds = prdIds + goodsItem.id + ",";
                  buyNums = buyNums + (aa.isChooseNum * goodsItem.packageNum) + ",";
                  fwOrgId = obj[0].fwOrgId;

                }
              });
            });
            if (!isTrue) {
              this.$message.info("请选择商品");
            } else {
              prdIds = prdIds.substr(0, prdIds.length - 1);
              buyNums = buyNums.substr(0, buyNums.length - 1);
              if (custId && (custId !== null || custId !== "")) {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/channelSubOrder",
                  query: {
                    prdIds: prdIds,
                    buyNums: buyNums,
                    orgId: fwOrgId,
                    custId: custId,
                    isChooseNum:proItem.isChooseNum,
                    pageType:'activity'
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              } else {
                let routeUrl = this.$router.resolve({
                  path: "/confirm/activity",
                  query: {
                    prdIds: prdIds,
                    buyNums: buyNums,
                    orgId: fwOrgId,
                    item: JSON.stringify(data),
                    isChooseNum:proItem.isChooseNum
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              }

            }
            // this.coverList.forEach(item => {
            //  if (item.id == id) {
            //
            //             }
            // });
          }
        }
      }
      if (data.discountTypeName !== "套购" && data.discountTypeName !== "组合购") {
        if (pause != "N") {
          this.$message.info("活动已暂停！");
          return;
        }
        this.coverList.forEach(item => {
          if (item.id == id) {
            let isTrue = false;
            let prdIds = "";
            let buyNums = "";
            let fwOrgId = "";
            let money = 0;
            let cherkNum = 0;
            item.productDtoList.forEach(goodsItem => {

              if (goodsItem.isCheck) {
                cherkNum = goodsItem.isChooseNum;
                money = goodsItem.deposit;
                isTrue = true;
                prdIds = prdIds + goodsItem.id + ",";
                buyNums = buyNums + goodsItem.isChooseNum + ",";
                fwOrgId = item.fwOrgId;
              }
            });
            if (!isTrue) {
              // const msg = this.$configTotal({
              //   route: this.$route.fullPath,
              //   id: "14180000079"
              // });
              // this.$message.info(msg && msg.msgDesc);
              this.$message.info("请选择商品");
            } else if (cherkNum <= 0) {
              this.$message.warning("商品数量不能为0，请重新选择");
            } else {
              prdIds = prdIds.substr(0, prdIds.length - 1);
              buyNums = buyNums.substr(0, buyNums.length - 1);
              if (custId && (custId !== null || custId !== "")) {
                // this.$router.push({
                //   path: "/confirm/channelSubOrder",
                //   query: {
                //     prdIds: prdIds,
                //     buyNums: buyNums,
                //     orgId: fwOrgId,
                //     custId: custId
                //   }
                // });
                let routeUrl = this.$router.resolve({
                  path: "/confirm/channelSubOrder",
                  query: {
                    prdIds: prdIds,
                    buyNums: buyNums,
                    orgId: fwOrgId,
                    custId: custId,
                    pageType:'activity'
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              } else {
                // this.$router.push({
                //   path: "/confirm/activity",
                //   query: {
                //     prdIds: prdIds,
                //     buyNums: buyNums,
                //     orgId: fwOrgId,
                //     item: JSON.stringify(data)
                //   }
                // });
                let routeUrl = this.$router.resolve({
                  path: "/confirm/activity",
                  query: {
                    prdIds: prdIds,
                    buyNums: buyNums,
                    orgId: fwOrgId,
                    item: JSON.stringify(data)
                  }
                });
                window.open(routeUrl.href, "_blank");
                return
              }

            }
          }
        });
      }
    }
  }
};
